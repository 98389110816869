.btn-go-up{
  position: fixed;
  padding: 4px 5px;
  bottom: 12px;
  left: 10px;
  width: 68px;
  color: #000000 !important;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: red;
  opacity: 0;
  visibility: hidden;
  z-index: 8;

  &.active{
    visibility: visible;
    opacity: 1;
  }
}
