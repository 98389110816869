#footer{
  position: relative;

  .newsletter-section {
		position: relative;
		padding: 17px 15px 0 15px;
		width: 100%;
		background-color: #241e1b;

		h2{
      line-height: 1.1;
			color: #ffffff;
			font-size: 1.40rem;
			text-align: center;
		}

    h5{
      line-height: 1;
      color: #ffffff;
      font-size: 1.1rem;
      text-align: center;
    }

		form{
			margin-top: 12px;
			text-align: center;
			@extend .flex-center-xy;
			flex-wrap: wrap;

			.form-group{
				width: 600px;
				margin-bottom: 0;
				@include placeholder(#999999 !important, 400, normal, 1.1rem);

				>div{
					width: 100%;
				}

				.form-control{
					height: 46px;
          color: #373737;
					text-align: center;
					font-size: 1.1rem;
					border-radius: 8px 0 0 8px;
					border-color: #ffffff;
					background-color: #ffffff;
				}
			}

			.btn-send{
				position: relative;
				padding-bottom: 8px;
				margin-left: -15px;
				width: 155px;
				height: 46px;
				color: #ffffff;
				font-size: 1.05rem;
				font-weight: 700;
				text-align: center;
				background-color: $red;
				border-color: $red;
				border-radius: 0 8px 8px 0;

				&:hover{
					border-color: $red2;
					background-color: $red2;
				}
			}
		}

		@media screen and (max-width: 991px){
			form{
				.form-group{
					width: calc(90vw - 155px);
				}
			}
		}
		@media screen and (max-width: 575px){
			h2{
				font-size: 1.5rem;
				line-height: 1.1;
			}

			form{
				.form-group{
					width: calc(100% - 127px);
					@include placeholder(#919293, 400, normal, 1.0rem);

					.form-control{
						height: 38px;
						font-size: 1.0;
					}
				}

				.btn-send{
					width: 127px;
					height: 38px;
					font-size: 1.0rem;
				}
			}
		}
	}

  .footer-section{
    position: relative;
    padding: 20px 0;
    color: #ffffff;
    background-color: #241e1b;

    a{
      display: inline-block;
      color: #ffffff;
    }

    .col-footer{
      padding-top: 14px;
      padding-bottom: 14px;

      h5{
        margin-bottom: 10px;
        color: #9c9c9c;
        font-size: 1.05rem;
        font-weight: 500;
      }

      .link-footer{
        font-size: 0.95rem;
        font-weight: 400;
      }

      .copy-text{
        color: #9c9c9c;
        font-size: 0.90rem;
        font-weight: 400;
      }
    }

    .col-copyright{
      text-align: right;

      .p-sm{
        color: #9c9c9c;
        font-size: 0.86rem;
        line-height: 1.35;

        &.p-icon{
          position: relative;
          padding-right: 32px;

          &:after{
            position: absolute;
            top: 2px;
            right: 0;
            color: #9c9c9c;
            font-family: "Font Awesome 5 Pro";
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1;
          }

          &.i-letter{
            &:after{
              content: "\f0e0";
            }
          }
          &.i-phone{
            &:after{
              content: "\f58f";
            }
          }
          &.i-map{
            &:after{
              content: "\f3c5";
            }
          }
        }
      }

      .btn-network{
        position: relative;
        display: inline-block;
        margin: 0 2px;

        span{
          @include flex-center-xy();
          width: 38px;
          flex: 0 0 38px;
          max-width: 38px;
          height: 38px;
          color: #000000;
          font-size: 1.2rem;
          border-radius: 50%;
          background: #ffffff;
        }

        &:hover{
          opacity: 0.8;
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-footer{
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: center;

        h5{
          margin-bottom: 2px;
          font-size: 1.05rem;
        }
      }

      .col-copyright{
        .p-sm{
          &.p-icon{
            padding-right: 0;

            &:after{
              display: none;
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .col-footer{
        font-size: 1.0rem;
      }
    }
  }
}
