#course-description-page{

  .banner-section{
    position: relative;
    //height: 270px;
    background-color: $soft-gray;
    @include flex-center-xy();

    &:after{
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,0.35);
      z-index: 1;
    }

    .inside{
      position: relative;
      display: inline-block;
      padding: 15px;
      width: 100%;
      text-align: center;
      z-index: 4;

      h1{
        color: $red;
        font-weight: 700;
      }

      .btn-enroll{
        display: inline-block;
        padding: 13px 16px;
        margin-top: 12px;
        width: 290px;
        max-width: 100%;
        color: #ffffff;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none !important;
        background-color: $red;
        border-radius: 6px;

        &:hover{
          background-color: $red2;
        }
      }
    }

    @media screen and (max-width: 767px){
      .inside{
        h1{
          font-size: 2.2rem;
        }
      }
    }
    @media screen and (max-width: 575px){
      height: inherit;
      min-height: 145px;

      .inside{
        h1{
          font-size: 1.8rem;
        }

        .btn-enroll{
          font-size: 0.85rem;
          padding: 12px 16px;
        }
      }
    }
  }

  .description-section{
    padding-top: 28px;
    padding-bottom: 24px;

    .subtitle{
      color: #241e1b;
      font-size: 1.15rem;
      font-weight: 700;
    }

    .col-descr{
      color: #595858;

      &.col-left {
        @media screen and (min-width: 992px) {
          padding-top: 24px;
          padding-bottom: 24px;
          padding-left: 25px;
          padding-right: 20px;
          border-radius: 8px;
          background: #f3f3f4;
        }
      }

      .img{
        padding: 0 0 10px 0;
        display: inline-block;
        width: 78%;
        max-width: 100%;

        img{
          width: 100%;
          border-radius: 6px;
          box-shadow: 0 0 4px rgba(0,0,0,0.3);
          background-color: $soft-gray;
        }
      }

      ol,ul{
        padding-left: 15px;
        font-weight: 500;
      }
      ul{
        list-style-type: disc;
      }

      .price{
        color: $red;
        font-size: 1.7rem;
        font-weight: 700;
      }

      .box-descr{
        display: block;
        line-height: 1.2;
        text-align: justify;
      }

      .btn-enroll-now{
        display: inline-block;
        padding: 15px 16px;
        margin-top: 12px;
        width: 100%;
        color: #ffffff;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none !important;
        background-color: $red;
        border-radius: 6px;

        &:hover{
          background-color: $red2;
        }
      }
    }
    @media screen and (max-width: 991px){
      .col-descr{
        .img{
          padding: 0 0 18px 0;
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 575px){
      .subtitle{
        font-size: 1.0rem;
      }

      .col-descr{
        font-size: 0.95rem;

        .btn-enroll-now{
          padding: 12px 16px;
          font-size: 1.0rem;
        }
      }
    }
  }

}
