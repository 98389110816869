// == Modal search (Header) ==
.modal-search-e{
  .modal-content{
    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.85rem 1rem;
        top: 10px;
        right: 16px;
        font-size: 28px;
        font-weight: 600;
      }
    }

    .modal-body{
      padding: 0;

      form{
        position: relative;
        width: 100%;

        .form-group{
          margin: 0 !important;

          .form-control{
            padding-left: 78px;
            padding-right: 38px;
            padding-bottom: 0.6rem;
          }
        }

        .btn-search{
          position: absolute;
          top: 0;
          left: 0;
          width: 56px;
          height: 100%;
          color: #ffffff;
          font-size: 18px;
          border: 0 !important;
          background-color: $red;
          z-index: 10;

          &:hover, &:focus, &:active{
            background-color: $red2 !important;
          }
        }
      }
    }
  }
}
// == ==

// == Modal Add-ons ==
.modal-add-ons{
  .modal-dialog{
    @media screen and (min-width: 768px){
      max-width: 532px;
    }
  }
  .modal-content{
    border-radius: 0;

    .modal-header{
      position: relative;
      padding: 0.4rem 1rem 0.4rem 1rem;
      color: #ffffff;
      border-radius: 0;
      background-color: $red;

      .modal-title{
        line-height: 1.1;
        color: #ffffff;
        font-size: 1.15rem;
        font-weight: 600;
      }

      .close{
        position: absolute;
        padding: 0.3rem 1rem;
        margin: inherit;
        top: 0;
        right: 0;
        color: #ffffff;
        font-weight: 600;
        text-shadow: none;
        opacity: 1;
      }
    }

    .modal-body{
      .c-name{
        margin-bottom: 14px;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;

        span{
          display: block;
          color: #7b7979;
          font-size: 80%;
        }
      }

      .text-check{
        position: relative;
        padding-left: 25px;
        margin-bottom: 6px;
        font-size: 1.05rem;

        &:before{
          position: absolute;
          content: "\f058";
          top: 2px;
          left: 0;
          font-family: "Font Awesome 5 Pro";
          font-size: 95%;
          color: $red;
          font-weight: 400;
        }
      }
    }
  }
}
// == ==

// == Modal Course PDF ==
.modal-reference{

  .modal-content{
    border: 0;

    .modal-header{
      padding: 0.3rem 1rem;
      color: #ffffff;
      background-color: $red;

      .modal-title{
        font-size: 1.1rem;
        font-weight: 600;
      }

      .close{
        color: #ffffff;
        text-shadow: none;
        opacity: 1;
      }
    }

    .modal-body{
      .d-block{
        min-height: 150px;

        &.loading{
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('~/assets/images/shared/loading.gif');
        }
      }

      iframe{
        border: 0;
        width: 100%;
        min-height: 400px;
        height: 75vh;
      }
    }
  }

}

// == ==
