#contact-page{

  .main-section{
    position: relative;
    width: 100%;
    overflow: hidden;

    .container{
      .col-form{
        padding-top: 15px;
        padding-bottom: 15px;
        align-self: center;

        .box-form{
          position: relative;
          width: 90%;

          form{
            margin-top: 25px;
          }

          .btn-send{
            display: inline-block;
            padding: 13px 15px;
            width: 60%;
            color: #ffffff;
            font-size: 1.15rem;
            font-weight: 600;
            border: 0 !important;
            border-radius: 6px;
            background-color: $red;

            &:hover{
              background-color: $red2;
            }
          }
        }
      }

      .col-image{
        .image{
          display: block;
          width: 50vw;
          height: 43vw;
          background-color: $soft-gray;
          background-image: url('../images/pages/contact/image.jpg');
        }
      }
    }

    @media screen and (max-width: 1399px){
      .container{
        .col-form{
          .box-form{
            width: 100%;
          }
        }
      }
    }
    @media screen and (max-width: 1299px){
      .container{
        .col-image{
          .image{
            height: 54vw;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      .container{
        .col-image{
          padding-left: 0;
          padding-right: 0;

          .image{
            width: 100%;
            height: 340px;
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .container{
        .col-form{
          .box-form{
            .btn-send{
              width: 100%;
            }
          }
        }
      }
    }
  }

}
