.row-items-xl-4{
  @media screen and (min-width: 1300px){
    .box-course-sample-1{
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.box-course-sample-1{
  .box-course{
    position: relative;
    display: block;
    height: 100%;
    color: #241e1b !important;
    text-decoration: none !important;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    border-radius: 6px;
    overflow: hidden;
    @include transition(200ms);

    .box-image{
      display: block;
      height: 160px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include transition(200ms);
    }

    .box-description{
      position: relative;
      display: block;
      padding: 16px 18px;
      width: 100%;

      .col-more{
        padding-right: 0;
        align-self: center;
      }

      .c-name{
        margin-top: 4px;
        margin-bottom: 6px;
        min-height: 44px;
        color: $red;
        font-size: 20px;
        line-height: 1.1;
        font-weight: 600;
      }

      .c-desc{
        min-height: 310px;
        max-height: 310px;
        font-size: 0.85rem;
        font-weight: 600;
        line-height: 1.35;
        overflow: hidden;
        text-align: justify;

        ul, ol {
          padding-left: 25px;
        }
      }

      .c-req{
        margin-top: 5px;
        color: #5f6160;
        font-size: 1.00rem;
        font-weight: 700;
      }

      .c-cer{
        margin-top: 10px;
        line-height: 1;
        font-size: 1.40rem;
        font-weight: 400;
      }

      .box-button{
        margin-top: 8px;

        .c-more{
          font-size: 0.75rem;
          line-height: 1.1;
        }

        .box-price{
          display: block;
          padding: 11px 10px 10px 16px;
          width: calc(100% + 18px);
          color: #ffffff;
          font-size: 0.90rem;
          letter-spacing: 1px;
          line-height: 1;
          border-radius: 6px 0 0 6px;
          background: $red;
        }

        .btn-enroll{
          display: inline-block;
          padding: 10px 15px;
          margin-top: 15px;
          width: 90%;
          color: #ffffff;
          font-size: 1.15rem;
          letter-spacing: 1px;
          text-align: center;
          text-decoration: none !important;
          border-radius: 6px;
          background-color: #241e1b;
          @include transition(200ms);

          &:hover {
            background-color: $red !important;
          }
        }

        .c-inf{
          margin-top: 12px;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }

    &:hover{
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);

      .box-image{
        opacity: 0.90;
      }

      .box-description{
        .box-button{
          .btn-enroll{
            background-color: #13100e;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px){
    .box-course{
      .box-description{
        .box-button{
          .col-more{
            margin-bottom: 6px;
            text-align: center;
          }

          .box-price{
            position: relative;
            width: calc(100% + 36px);
            left: -18px;
            border-radius: 0;
            text-align: center;
          }

          .btn-enroll{
            position: relative;
            margin-top: 8px;
            width: calc(100% + 36px);
            left: -18px;
          }
        }
      }
    }

  }
}

.box-course-sample-2{
  position: relative;
  display: flex;
  padding: 6px 0;
  margin-bottom: 25px;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;

  &:last-child{
    margin-bottom: 0;
  }

  .col-image{
    padding: 0;
    flex: 0 0 140px;
    max-width: 140px;

    .img{
      display: block;
      height: 100%;
      border-radius: 2px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: $soft-gray;
    }
  }

  .col-description{
    padding-right: 0;

    .status{
      position: absolute;
      top: 1px;
      right: 0;
      color: $red;
      font-size: 0.65rem;
      font-weight: 600;
      line-height: 1;

      i{
        position: relative;
        top: 1px;
        font-size: 115%;
      }
    }

    .c-name{
      padding-right: 53px;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 0.70rem;

      &.completed{
        padding-right: 80px;
      }
    }

    .c-desc{
      font-size: 1.00rem;
      font-weight: 700;
      line-height: 1.0;
    }

    .c-update{
      margin-top: 5px;
      font-size: 0.70rem;

      strong{
        font-weight: 600;
      }
    }

    .box-progress{
      margin-top: 5px;
      align-items: center;

      .col-number{
        flex: 0 0 42px;
        max-width: 42px;
        font-size: 0.75rem;
      }

      .col-bar{
        .progress{
          .progress-bar{
            background-color: $red;
          }
        }
      }
    }

    .box-buttons{
      margin-top: 10px;

      ._btn{
        display: inline-block;
        padding: 5px 12px 5px 12px;
        width: 130px;
        max-width: 100%;
        color: #241e1b;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: center;
        text-decoration: none !important;
        border: 1px solid transparent;
        border-radius: 0.30rem;
        @include transition(250ms);

        &.bg-red{
          color: #ffffff;
          background-color: $red;

          &:hover{
            background-color: $red2;
          }
        }

        &.outline-black{
          color: #241e1b;
          border-color: #241e1b;

          &:hover{
            color: #000000;
            border-color: #000000;
          }
        }
      }
    }
  }

  .col-progress{
    flex: 0 0 270px;
    max-width: 270px;

    ._title{
      margin-bottom: 10px;
      font-size: 0.95rem;
      font-weight: 700;
    }

    >._row{
      padding-top: 2px;
      padding-bottom: 2px;
      align-items: center;
    }

    .col-label{
      .txt{
        position: relative;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 40px;
        color: #4a4a4a;
        font-size: 0.80rem;
        line-height: 1.0;

        &:before{
          position: absolute;
          left: 4px;
          color: #4a4a4a;
          font-family: "Font Awesome 5 Pro";
          font-size: 1.4rem;
        }

        &.icon-1{
          &:before{
            content: "\f19d";
            font-weight: 300;
          }
        }
        &.icon-2{
          &:before{
            content: "\f017";
            font-weight: 300;
            left: 7px;
          }
        }
        &.icon-3{
          &:before{
            content: "\f005";
            font-weight: 300;
            left: 6px;
          }
        }
      }
    }

    .col-bubble{
      padding: 0;
      max-width: 116px;
      text-align: right;

      .bubble{
        position: relative;
        display: inline-block;
        padding: 8px 10px 7px 10px;
        color: #ffffff;
        line-height: 1;
        font-size: 0.65rem;
        text-align: center;
        border-radius: 6px;
        background-color: #4a4a4a;
      }

      &.completed{
        min-width: 141px;

        .bubble{
          padding: 8px 8px 7px 8px;
        }
      }
    }
  }

  .col-add-ons{
    padding: 12px 0 0 0;

    ._title{
      margin-bottom: 3px;
      font-size: 0.95rem;
      font-weight: 700;
    }

    .box-add-ons{
      position: relative;
      display: block;

      >.row{
        padding-top: 2px;
        padding-bottom: 2px;
        align-items: center;
      }

      .col-price{
        font-weight: 700;
        flex: 0 0 150px;
        max-width: 150px;
        font-size: 0.90rem;
        text-align: right;
      }
    }
  }

  .col-certificates{
    flex: 0 0 228px;
    max-width: 228px;
    align-self: center;

    ._btn{
      display: inline-block;
      padding: 7px 12px 7px 12px;
      margin: 3px 0;
      width: 100%;
      color: #241e1b;
      font-size: 0.75rem;
      font-weight: 600;
      text-align: center;
      text-decoration: none !important;
      border: 1px solid #241e1b;
      line-height: 1.0;

      &:hover{
        color: #ffffff;
        background-color: #241e1b;
      }
    }
  }

  @media screen and (max-width: 1199px){
    .col-certificates{
      flex: 0 0 180px;
      max-width: 180px;
    }
  }
  @media screen and (max-width: 767px){
    .col-progress{
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      margin-top: 7px;
    }

    .col-certificates{
      padding: 0;
      margin-top: 10px;
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;

      ._btn{
        width: calc(50% - 6px);
        padding: 7px 15px 7px 15px;
        margin: 3px 2px;

        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 575px){
    .col-image{
      flex: 0 0 135px;
      max-width: 135px;
    }

    .col-description{
      .c-update{
        font-size: 0.65rem;
      }

      .box-buttons{
        ._btn{
          width: 100%;
        }
      }
    }
  }
}
