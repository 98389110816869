#home-page{

  // == Banners ==
	.banner-section{
		position: relative;
		padding: 0;

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					background-color: $soft-gray;

					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		.box-content {
			position: absolute;
			top: 0;
			left: 50px;
			width: calc(100% - 100px);
			height: 100%;
			z-index: 10;

			>.container {
				height: 100%;

				>.row {
					height: 100%;
					align-items: center;
				}

				.title {
					display: inline-block;
					padding: 8px 17px 8px 18px;
					margin-bottom: 12px;
					font-size: 1.5rem;
					font-weight: 700;
					// text-shadow: 2px 2px 1px rgba(255,255,255,0.9);
					border-radius: 14px;
					background-color: #ffffff;
				}

				.box-title {
					position: relative;
					display: inline-block;
					// width: 290px;
					padding: 10px 22px;
					color: #ffffff;
					font-size: 1.2rem;
					font-weight: 600;
					letter-spacing: 2px;
					text-align: center;
					border-radius: 22px;
					background-color: $red;

					&:before {
						content: " ";
						position: absolute;
						bottom: -15px;
						left: 26px;
						width: 0;
					  height: 0;
					  border-left: 14px solid transparent;
					  border-right: 14px solid transparent;

					  border-top: 16px solid $red;
					}
				}

				.box-select {
					display: inline-block;
					margin-top: 25px;
					width: 100%;

					.custom-select {
						padding: 0.35rem 1.75rem 0.35rem 0.85rem;
						width: 290px;
						height: inherit;
						font-weight: 600;
						border: 3px solid #2c2b27;
						border-radius: 8px;
					}

					&.disabled {
						opacity: 0.85;

						.bx {
							display: inline-block;
							cursor: no-drop;
						}

						.custom-select {
							pointer-events: none;
						}
					}
					&.loading {
						.bx {
							cursor: wait;
						}
					}
				}

				.btn-send {
					padding: 0.40rem 0.75rem;
					width: 290px;
					color: #ffffff;
					font-weight: 600;
					letter-spacing: 1px;
					border: 0;
					border-radius: 8px;
					background-color: $red;
				}
			}
		}

		@media screen and (min-width: 992px) {
			.swiper-desktop{
				.swiper-slide {
					height: 620px;

					a {
						height: 100%;

						img {
							display: none;
						}
					}
				}
			}

			.box-content {
				.col-form-y {
					margin-top: -130px;
				}
			}

			@media screen and (max-width: 1499px) {
				.swiper-desktop{
					.swiper-slide {
						height: 480px;
					}
				}

				.box-content {
					.col-form-y {
						margin-top: -130px;
					}
				}
			}
			@media screen and (max-width: 1399px) {
			}
		}
		@media screen and (max-width: 991px){
			height: 70vh;

			.swiper-desktop{
				display: none;
			}
			.swiper-mobile	{
				display: block;
				height: 70vh;
				background-color: $soft-gray;
			}
		}
		@media screen and (max-width: 575px){
			.box-content {
				text-align: center;
			}
		}
	}
	// == ==

  // == Info 1 ==
  .info-s1-section{
    padding-top: 30px;
    padding-bottom: 30px;
		background-color: rgba(255,255,255,1.0);

    .col-about-us{
      h2{
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.15;
      }
    }

    .col-info{
      padding-top: 15px;
      padding-bottom: 15px;
			text-align: center;

			.icon {
				max-width: 100%;
				max-height: 160px;
			}

      .number{
        margin-bottom: 7px;
        color: $red;
        font-size: 2.60rem;
        font-weight: 700;
      }

      p{
        font-weight: 700;
        line-height: 1.4;
      }
    }

    @media screen and (min-width: 992px){
			position: relative;
			margin-top: -125px;
			z-index: 20;

      .col-info{
        p{
          font-size: 1.05rem;
        }
      }

      @media screen and (max-width: 1499px){
        margin-top: -120px;
      }
			@media screen and (max-width: 1299px){
        margin-top: -135px;
      }
    }
    @media screen and (max-width: 991px){
      .col-about-us, .col-info{
        text-align: center;
      }

      .col-info{
        .number{
          margin-bottom: 0;
          font-size: 2.3rem;
        }

        p{
          font-size: 1.0rem;
        }
      }
    }
  }
  // == ==

  // == Why ==
  // .why-section{}
  // == ==

	// == Reasons ==
	.reasons-section{
	  position: relative;
	  padding-top: 38px;
	  padding-bottom: 38px;
	  margin-bottom: 5px;
	  color: #241e1b;
	  text-align: center;
	  background-color: #ffffff;

	  &:before, &:after{
	    content: " ";
	    position: absolute;
	    margin-top: -42px;
	    top: 50%;
	    width: 104px;
	    height: 85px;
	    background-repeat: no-repeat;
	    background-size: contain;
	  }

	  &:before{
	    left: 0;
	    background-position: right center;
	    background-image: url('../images/pages/home/a-right.png');
	  }
	  &:after{
	    right: 0;
	    background-position: left center;
	    background-image: url('../images/pages/home/a-left.png');
	  }

		.col-title {
			margin-bottom: 20px;

			.sec-title {
				font-size: 1.85rem;
			}
		}

		.col-reason {
			margin-top: 10px;
			margin-bottom: 10px;

			.p-icon {
				margin-bottom: 18px;

				img {
					max-width: 92%;
					height: 130px;
				}
			}
		}
		a.col-reason {
			color: #241e1b;
			text-decoration: none !important;

			&:hover {
				opacity: 0.85;
			}
		}

	  .sec-title{
	    font-size: 1.55rem;
	    font-weight: 700;
	    line-height: 1.15;
	  }

	  p{
	    font-size: 1.1rem;
	  }

	  .line{
	    border-top: 4px solid $red;
	    width: 182px;
	  }

	  @media screen and (max-width: 920px){
	    &:before, &:after{
	      top: 0;
	      width: 65px;
	      background-size: cover;
	    }
	  }
	  @media screen and (max-width: 575px){
	    padding-bottom: 28px;

	    &:before, &:after{
	      margin-top: -28px;
	      width: 55px;
	      height: 56px;
	    }

			.p-icon {
				img {
					height: 100px !important;
				}
			}

	    p{
	      font-size: 1.2rem;
	    }
	  }
	}
	// == ==

	// == Courses ==
	.courses-section{
		padding-top: 30px;
		padding-bottom: 40px;

		.sec-title{
      font-size: 1.85rem;
      font-weight: 700;
      line-height: 1.15;
    }

		.col-title{}

		.col-carousel{
			margin-top: 25px;

			.swiper{
				.swiper-slide{
					padding-bottom: 50px;
				}

				.swiper-button-prev, .swiper-button-next {
					margin-top: -70px;
					width: 30px;
					height: 44px;
					color: #ffffff;
					background-color: $red;

					&:after {
						font-size: 1.3rem;
						font-weight: 700;
					}

					&:hover {
						background-color: $red2;
					}
				}
				.swiper-button-prev {
					left: 0;
				}
				.swiper-button-next {
					right: 0;
				}

				.swiper-pagination{
					width: 226px !important;
					bottom: 0;

					.swiper-pagination-bullet{
						margin: 0 10px;
						width: 20px;
						height: 4px;
						border-radius: 0;
						opacity: 1 !important;
						transform: scale(1) !important;
						background-color: #c0c0c0;
					}

					.swiper-pagination-bullet-active{
						width: 48px !important;
						background-color: #241e1b !important;
					}
				}
			}
		}

		.box-courses-sample-1{
			.box{
				position: relative;
				display: block;
				text-decoration: none !important;
				border-radius: 6px;
				overflow: hidden;
				@include transition(150ms);

				.box-image{
					display: block;
					background-color: $soft-gray;

					img{
						width: 100%;
					}
				}

				.box-name{
					position: relative;
					padding: 8px 46px 8px 15px;
					@include flex-center-xy();
					flex: 0 0 100%;
					max-width: 100%;
					height: 60px;
					color: #ffffff;
					background-color: $red;

					&:after{
						content: "\f178";
						position: absolute;
						top: 50%;
						margin-top: -14px;
						right: 15px;
						font-family: "Font Awesome 5 Pro";
						font-size: 1.7rem;
						font-weight: 300;
						color: #ffffff;
						line-height: 1;
						z-index: 5;
						@include transition(250ms);
					}

					.inside{
						display: inline-block;
						width: 100%;

						h6{
							font-size: 1.1rem;
							font-weight: 400;
							line-height: 1.1;
						}
					}
				}

				&:hover{
					opacity: 0.90;

					.box-name{
						&:after{
							right: 11px;
						}
					}
				}
			}


		}
	}
	// == ==

	// == How ==
	.how-section{
		padding-top: 25px;
		padding-bottom: 25px;

		.col-image{
			flex: 0 0 637px;
			max-width: 637px;
			padding: 0;

			img{
				width: 100%;
				border-radius: 8px;
				box-shadow: 0 0 6px rgba(0,0,0,0.15);
			}
		}

		.col-info{
			align-self: center;

			.box-title{
				width: 90%;
				margin: 0 auto;

				.sec-title{
		      font-size: 1.80rem;
		      font-weight: 700;
		      line-height: 1.15;
		    }

				p{
					font-size: 1.25rem;
					font-weight: 400;
				}
			}

			.box-steps{
				position: relative;
				margin-top: 42px;

				&:after{
					content: "\f0da";
					position: absolute;
					top: 18px;
					right: -3px;
					height: 29px;
					font-family: "Font Awesome 5 Pro";
					font-size: 1.8rem;
					font-weight: 700;
					line-height: 1;
					color: $red;
					line-height: 1;
				}

				.col-step{
					padding: 0;

					.box-line{
						position: relative;
						text-align: center;
						margin-bottom: 5px;

						&:before{
							content: " ";
							position: absolute;
							margin-top: -2px;
							top: 50%;
							left: 0;
							width: 100%;
							border-top: 1px solid $red;
						}

						&:after{
							content: "\f312";
							display: inline-block;
							color: $red;
							font-family: "Font Awesome 5 Pro";
							font-size: 2.8rem;
							font-weight: 800;
						}
					}

					.box-text{
						position: relative;
						margin-top: 14px;
						width: 70%;
						min-width: 210px;
						margin: 0 auto;
						text-align: center;
						line-height: 1.4;
					}
				}
			}
		}

		@media screen and (max-width: 1299px){
			.col-image{
				flex: 0 0 520px;
				max-width: 520px;
			}
		}
		@media screen and (max-width: 1199px){
			.col-image{
				flex: 0 0 42%;
				max-width: 42%;
			}

			.col-info{
				.box-steps{
					.col-step{
						.box-text{
							padding: 0px 12px;
							width: 100%;
							min-width: inherit;
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px){
			.col-image{
				display: none;
			}

			.col-info{
				.box-title{
					width: 100%;
				}

				.box-steps{
					margin-top: 20px;
				}
			}
		}
		@media screen and (max-width: 575px){
			.col-info{
				.box-steps{
					&:after{
						display: none;
					}

					.col-step{
						margin-bottom: 15px;

						.box-text{
							padding: 0 15px;
						}
					}
				}
			}
		}
	}
	// == ==

	// == How v2 ==
	.how-section-v2{
		position: relative;
		padding-top: 32px;
		padding-bottom: 32px;
		background-color: #e0e3e3;

		&:before {
			content: " ";
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -21px;
			width: 100%;
			border-top: 1px solid $red;
		}

		.col-info{
			align-self: center;

			.box-title{
				width: 90%;
				margin: 0 auto;

				.sec-title{
		      font-size: 1.80rem;
		      font-weight: 700;
		      line-height: 1.15;
		    }

				p{
					font-size: 1.25rem;
					font-weight: 400;
				}
			}

			.box-steps{
				position: relative;
				margin-top: 35px;

				.col-step{
					padding: 0;

					.box-line{
						position: relative;
						text-align: center;
						margin-bottom: 5px;

						// &:before{
						// 	content: " ";
						// 	position: absolute;
						// 	margin-top: -2px;
						// 	top: 50%;
						// 	left: 0;
						// 	width: 100%;
						// 	border-top: 1px solid $red;
						// 	z-index: -1;
						// }

						&:after{
							content: " ";
							display: inline-block;
							width: 150px;
							height: 150px;
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
						}

						&.s-1 {
							&:after{
								background-image: url('../images/pages/home/step-1.svg');
							}
						}
						&.s-2 {
							&:after{
								background-image: url('../images/pages/home/step-2.svg');
							}
						}
						&.s-3 {
							&:after{
								background-image: url('../images/pages/home/step-3.svg');
							}
						}
					}

					.box-text{
						position: relative;
						margin-top: 14px;
						width: 70%;
						min-width: 210px;
						margin: 0 auto;
						text-align: center;
						line-height: 1.4;

						.s-title {
							margin-bottom: 4px;
							color: $red;
							font-weight: 700;
						}

						p {
							font-size: 1.1rem;
							font-weight: 500;
							line-height: 1.25;
						}
					}
				}
			}
		}

		@media screen and (max-width: 991px){
			&:before {
				margin-top: -27px;
			}

			.col-info{
				.box-title{
					width: 100%;
				}

				.box-steps{
					.col-step {
						padding-left: 10px;
						padding-right: 10px;

						.box-line {
							&:after {
								width: 130px;
								height: 130px;
							}
						}

						.box-text {
							min-width: 100%;
							width: 100%;

							.s-title {
								min-height: 40px;
								font-size: 1.05rem;
							}

							p {
								font-size: 0.95rem;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 575px){
			&:before {
				display: none;
			}

			.col-info{
				.box-steps{
					margin-top: 0;

					.col-step {
						margin-bottom: 18px;
						padding-left: 10px;
						padding-right: 10px;

						&:last-child {
							margin-bottom: 0;
						}

						.box-line {
							margin-bottom: 0;

							&:after {
								width: 130px;
								height: 130px;
							}
						}

						.box-text {
							min-width: 100%;
							width: 100%;

							.s-title {
								min-height: auto;
								font-size: 1.20rem;
							}

							p {
								font-size: 1.0rem;
							}
						}
					}
				}
			}
		}
	}
	// == ==

	// == Contact ==
	.contact-section{
		position: relative;
		padding: 18px 0;
		background-color: #efefef;

		a{
			color: #241e1b;
		}

		.col-text-1{
			padding-top: 14px;
			padding-bottom: 14px;

			h6{
				margin-bottom: 5px;
			}
			h4{
				font-size: 1.7rem;
				font-weight: 700;
				line-height: 1.15;
			}
		}

		.col-text-2{
			h5{
				font-size: 1.4rem;
			}
		}

		.col-text-3{
			text-align: right;

			.col-label{
				h5{
					font-size: 1.4rem;
				}
			}

			.col-network{
				margin-top: 6px;
				margin-bottom: 6px;

				a{
					display: inline-block;
					margin: 0 5px;
					text-decoration: none !important;
					line-height: 1;

					span{
						@include flex-center-xy();
						width: 38px;
						height: 38px;
						color: #ffffff;
						font-size: 1.3rem;
						border-radius: 50%;

						i{
							position: relative;
							top: 1px;
						}
					}

					&:hover{
						opacity: 0.85;
					}

					&.btn-in{
						span{
							background: #f09433;
							background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
							background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
							background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
						}
					}

					&.btn-tw{
						span{
							background-color: #17a5d3;
						}
					}
					&.btn-fb{
						span{
							background-color: #0a73f7;
						}
					}
				}

				.onestop{
					display: block;
				}
			}
		}

		@media screen and (max-width: 991px){
			.col-text-1, .col-text-2, .col-text-3{
				padding-top: 4px;
				padding-bottom: 4px;
				text-align: center;
			}

			.col-text-1{
				h6{
					margin-bottom: 0;
					line-height: 1;
				}
			}
		}
	}
	// == ==

}
