#about-us-page{

  .top-section{
    position: relative;
    padding-top: 15px;
    z-index: 5;

    &:before{
      content: " ";
      position: absolute;
      bottom: -9%;
      left: 0;
      width: 200px;
      height: 229px;
      background-image: url('../images/pages/about-us/hexagon-1.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 5;
    }

    .col-image{
      img{
        float: right;
        width: 43.5vw;
        border-radius: 18px;
      }
    }

    .col-text{
      font-size: 1.15rem;
      font-weight: 500;
      line-height: 1.3;
      text-align: justify;

      .title{
        margin-bottom: 15px;
        font-size: 2.3rem;
        font-weight: 700;
      }
    }

    @media screen and (min-width: 992px){
      .col-text{
        margin-left: 8.333333%;
      }

      @media screen and (max-width: 1599px){
        .col-text{
          flex: 0 0 54.5%;
          max-width: 54.5%;
          margin-left: 3%;

          .title{
            font-size: 2.0rem;
            line-height: 1.1;
          }
        }
      }
      @media screen and (max-width: 1199px){
        .col-text{
          margin-left: 0;
          flex: 0 0 50%;
          max-width: 50%;
          font-size: 0.95rem;

          .title{
            font-size: 1.6rem;
            line-height: 1;
          }
        }

        .col-image{
          img{
            width: 60.5vw;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      &:before{
        width: 120px;
      }

      .col-image{
        img{
          width: 100%;
        }
      }
      .col-text{
        margin-top: 22px;

        .title{
          font-size: 1.8rem;
          line-height: 1;
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 878px){
      &:before{
        display: none;
      }
    }
    @media screen and (max-width: 575px){
      .col-text{
        font-size: 0.95rem;
      }
    }
  }

  .middle-section{
    position: relative;
    padding: 100px 0;
    margin-top: -2%;
    color: #ffffff;
    line-height: 1.3;
    text-align: justify;
    background-color: $red;

    &:after{
      content: " ";
      position: absolute;
      bottom: -10%;
      right: 0;
      width: 190px;
      height: 144px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../images/pages/about-us/hexagon-2.png');
      z-index: 5;
    }

    @media screen and (max-width: 991px){
      margin-top: 25px;
      padding: 50px 0;

      &:after{
        width: 150px;
        height: 120px;
        bottom: -5%;
      }
    }
    @media screen and (max-width: 616px){
      padding: 30px 0;
      font-size: 0.95rem;

      &:after{
        display: none;
      }
    }
  }

  // == Info 1 ==
  .info-s1-section{
    padding-top: 30px;
    padding-bottom: 30px;

    .col-about-us{
      align-self: center;

      h2{
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.15;
      }
    }

    .col-num{
      padding-top: 15px;
      padding-bottom: 15px;

      .number{
        margin-bottom: 7px;
        color: $red;
        font-size: 2.60rem;
        font-weight: 700;
      }

      p{
        font-weight: 500;
        line-height: 1.4;
      }
    }

    @media screen and (min-width: 992px){
      .col-num{
        p{
          font-size: 1.15rem;
        }
      }

      @media screen and (max-width: 1399px){
        .col-num{
          .number{
            font-size: 2.3rem;
          }

          p{
            font-size: 1.0rem;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      .col-about-us, .col-num{
        text-align: center;
      }

      .col-num{
        .number{
          margin-bottom: 0;
          font-size: 2.3rem;
        }

        p{
          font-size: 1.0rem;
        }
      }
    }
  }
  // == ==

}
