#faqs-page{

  .main-section{
    padding-top: 15px;
    padding-bottom: 15px;

    .col-title{
      text-align: center;

      .title-st-1{
        font-size: 1.95rem;
      }
    }

    .col-faqs{
      .box-faqs{
        position: relative;
        display: inline-block;
        width: 100%;
        margin-top: 25px;

        .card{
          position: relative;
          display: inline-block;
          padding: 15px 22px 15px 22px;
          margin-bottom: 12px;
          width: 100%;
          font-size: 0.92rem;
          line-height: 1.20;
          text-align: justify;
          background-color: rgba(0, 0, 0, 0.01);
          border: 2px solid #ebebeb;
          border-radius: 8px;

          .card-header{
            padding: 0 !important;
            border: 0;
            background-color: transparent !important;
            @include transition(250ms);

            .question{
              position: relative;
              display: block;
              padding-right: 18px;
              margin-bottom: 2px;
              color: $red;
              cursor: pointer;
              font-size: 1.05rem;
              font-weight: 700;
              text-decoration: none !important;

              &.collapsed, &.not-collapsed{
                &:after{
                  position: absolute;
                  display: inline-block;
                  right: 0;
                  color: $red;
                  font-family: "Font Awesome 5 Pro";
                  font-size: 1.6rem;
                  font-weight: 600;
                  line-height: 1;
                  @include transition(150ms);
                }
              }

              &.collapsed{
                &:after{
                  content: "\f0da";
                  top: -2px;
                }
              }
              &.not-collapsed{
                &:after{
                  content: "\f0d7";
                  top: -2px;
                }
              }
            }

            &:hover{
              .question{
                color: #820212;

                &.collapsed, &.not-collapsed{
                  &:before{
                    color: #820212;
                  }
                }
              }
            }
          }

          .card-body{
            padding: 3px 0 0 0;
          }
        }
      }
    }
  }

}
