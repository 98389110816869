#courses-results-page{

  .results-section{
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 54vh;

    .col-title{
      .box-simple-title{
        position: relative;
        display: inline-block;
        padding: 14px 28px 13px 0px;
        margin-bottom: 10px;
        color: #ffffff;
        width: 130px;
        border-radius: 0 8px 8px 0;
        background-color: $red;

        &:after{
          content: " ";
          position: absolute;
          top: 0;
          right: 130px;
          width: 30vw;
          height: 100%;
          background: $red;
          z-index: 2;
        }

        h6{
          line-height: 1;
          font-size: 1.15rem;
          font-weight: 400;
          text-transform: uppercase;
        }

        &.search{
          width: 230px;

          &:after{
            right: 230px;
          }
        }
      }

      .box-state{
        display: block;

        .box{
          display: flex;
          margin-bottom: 8px;
          flex: 0 0 350px;
          max-width: 350px;
          flex-wrap: wrap;
          align-items: center;
          border: 2px solid #241e1b;
          border-radius: 0 8px 8px 0;

          .col-label{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 15px 0 0;
            height: 38px;
            flex: 0 0 127px;
            max-width: 127px;
            color: #ffffff;
            font-weight: 600;
            text-align: right;
            text-transform: uppercase;
            background: #241e1b;

            &:before{
              content: " ";
              position: absolute;
              top: -2px;
              right: 127px;
              width: 30vw;
              height: calc(100% + 4px);
              background: #241e1b;
              z-index: 2;
            }

            span{
              letter-spacing: 5px;
              font-size: 1.15rem;
            }
          }

          .col-select{
            padding: 0;

            select{
              border: 0;
              border-radius: 0 8px 8px 0;

              option{
                &:first-child{
                  color: #999999 !important;
                }
              }
            }
          }
        }
      }

      .box-title{
        margin-bottom: 24px;

        h2{
          font-size: 2.6rem;
          font-weight: 700;
          line-height: 1;
        }

        &.search{
          h2{
            font-size: 1.65rem;

            span{
              color: #918e8d;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px){
      .col-title{
        .box-simple-title{
          padding: 10px 28px 9px 0px;
          width: 115px;

          h6{
            font-size: 1.0rem;
          }

          &:after{
            right: 115px;
          }

          &.search{
            width: 195px;

            &:after{
              right: 195px;
            }
          }
        }

        .box-state{
          .box{
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        .box-title{
          h2{
            font-size: 2.2rem;
          }

          &.search{
            h2{
              font-size: 1.15rem !important;
            }
          }
        }
      }
    }
  }

}
