#corporate-discounts-page{
  position: relative;
  width: 100%;
  overflow: hidden;

  .banner-section{
    position: relative;
    height: 140px;
    background-color: $soft-gray;
    @include flex-center-xy();

    &:after{
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,0.35);
      z-index: 1;
    }

    .inside{
      position: relative;
      display: inline-block;
      padding: 15px;
      width: 100%;
      text-align: center;
      z-index: 4;

      h1{
        color: $red;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 575px){
      height: inherit;
      min-height: 95px;

      .inside{
        h1{
          font-size: 1.8rem;
        }
      }
    }
  }

  .info-section{
    position: relative;
    z-index: 5;

    .col-image{
      img{
        float: left;
        width: 116%;
      }
    }

    .col-text{
      line-height: 1.3;
      text-align: justify;

      .title- {
        margin-bottom: 20px;
        color: $red;
        font-size: 2.3rem;
        font-weight: 700;
        text-align: center;
      }
    }

    @media screen and (min-width: 992px){
      .col-text{
        margin-right: 8.333333%;
      }

      @media screen and (max-width: 1599px){
        .col-text{
          flex: 0 0 46.5%;
          max-width: 46.5%;
          margin-right: 3%;
        }
      }
      @media screen and (max-width: 1199px){
        .col-text{
          margin-right: 0;
          flex: 0 0 50%;
          max-width: 50%;
          font-size: 0.95rem;
        }

        .col-image{
          img{
            width: 60.5vw;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      &:before{
        width: 120px;
      }

      .col-image{
        margin-top: 15px;

        img{
          width: 100%;
        }
      }
      .col-text{
        margin-top: 13px;
      }
    }
    @media screen and (max-width: 878px){
      &:before{
        display: none;
      }
    }
    @media screen and (max-width: 575px){
      .col-text{
        font-size: 0.95rem;
      }
    }
  }

}
