#page-404{
  padding-top: 25px;
  padding-bottom: 35px;
  color: #ffffff;
  background-color: $black;

  a{
    color: #ffffff;
  }

  .container{
    text-align: center;

    .logo{
      width: 290px;
    }

    h1{
      font-weight: 600;
    }
    h2{
      font-weight: 400;
    }

    p.lg{
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 575px){
    .container{
      .logo{
        width: 270px;
      }
    }
  }
}
