.why-section{
  position: relative;
  padding-top: 38px;
  padding-bottom: 38px;
  margin-bottom: 5px;
  color: #ffffff;
  text-align: center;
  background-color: #241e1b;

  &:before, &:after{
    content: " ";
    position: absolute;
    margin-top: -42px;
    top: 50%;
    width: 104px;
    height: 85px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:before{
    left: 0;
    background-position: right center;
    background-image: url('../images/pages/home/a-right.png');
  }
  &:after{
    right: 0;
    background-position: left center;
    background-image: url('../images/pages/home/a-left.png');
  }

  .sec-title{
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.15;
  }

  p{
    font-size: 1.5rem;
  }

  a{
    color: #ffffff;
  }

  .line{
    border-top: 4px solid #ffffff;
    width: 182px;
  }

  @media screen and (max-width: 920px){
    &:before, &:after{
      top: 0;
      width: 65px;
      background-size: cover;
    }
  }
  @media screen and (max-width: 575px){
    padding-bottom: 28px;

    &:before, &:after{
      margin-top: -28px;
      width: 55px;
      height: 56px;
    }

    p{
      font-size: 1.2rem;
    }
  }
}
