#enroll-page{
  position: relative;
  min-height: 79vh;

  &:before{
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56vh;
    background-color: #241e1b;
  }

  .main-section{
    position: relative;
    z-index: 5;

    >.box-main-title{
      position: relative;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      color: #ffffff;

      h1{}
    }

    .main-container{
      .box-white{
        position: relative;
        display: block;
        padding: 20px 22px;
        box-shadow: 0 0 12px rgba(0,0,0,0.15);
        border-radius: 8px;
        background-color: #ffffff;
      }

      .box-title{
        margin-bottom: 12px;
        font-size: 1.1rem;
        font-weight: 600;
      }

      .img-payments{
        width: 90%;
      }

      .custom-promo-group{
        label, legend{
          color: $red;
        }

        >div{
          display: flex;
          padding: 0;
          flex: 0 0 100%;
          max-width: 100%;
          border-color: $red !important;

          .form-control{
            flex: 0 0 calc(100% - 76px);
            max-width: calc(100% - 76px);
          }

          .btn-add-promo{
            padding: .375rem .50rem;
            margin: 0;
            font-size: 0.85rem;
            flex: 0 0 76px;
            max-width: 76px;
            font-weight: 500;
            border-color: $red;
            background-color: $red;
          }
        }
      }

      .box-add-ons{
        >.row{
          padding-top: 8px;
          padding-bottom: 8px;

          .col-text{}

          .col-price{
            font-weight: 700;
          }
        }

        @media screen and (min-width: 576px){
          >.row{
            .col-price{
              flex: 0 0 150px;
              max-width: 150px;
              text-align: right;
            }
          }
        }
        @media screen and (max-width: 575px){
          >.row{
            .col-price{
              padding-top: 3px;
              padding-left: 59px;
            }
          }
        }
      }

      .box-course-info{
        .col-image{
          .img{
            display: block;
            height: 100%;
            background-color: $soft-gray;

            img{
              width: 100%;
            }
          }
        }

        .col-text{
          .c-name{
            margin-bottom: 6px;
            font-weight: 600;
            font-size: 0.80rem;
          }

          .c-desc{
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.10;
          }

          .c-req{
            margin-top: 5px;
            color: $red;
            font-size: 0.90rem;
            font-weight: 700;
          }

          .c-cer{
            margin-top: 10px;
            line-height: 1;
            font-size: 0.95rem;
            font-weight: 400;
          }

          .box-button{
            margin-top: 20px;
            align-items: center;

            .c-more{
              display: inline-block;
              color: #241e1b;
              font-size: 0.75rem;
              font-weight: 500;
              line-height: 1.1;
            }

            .box-price{
              display: block;
              padding: 11px 10px 10px 16px;
              width: calc(100% + 18px);
              color: #ffffff;
              font-size: 0.90rem;
              letter-spacing: 1px;
              line-height: 1;
              border-radius: 6px;
              background: $red;
            }
          }

          .table-summary{
            width: 100%;

            th,td{
              vertical-align: middle;
              font-size: 0.95rem;
              line-height: 1.3;
            }

            th{
              color: #9b9b9b;
              font-weight: 600;
            }
            td{
              font-weight: 600;
              text-align: right;
            }
          }
        }
      }

      .box-submit{
        margin-top: 25px;
        margin-bottom: 20px;
        text-align: center;

        .btn-submit{
          padding: 0.7rem 0.75rem;
          width: 100%;
          color: #ffffff !important;
          font-size: 1.1rem;
          font-weight: 600;
          border-radius: 0;
          border-color: $red;
          background-color: $red;

          @media screen and (max-width: 575px){
            width: 100%;
          }
        }
      }

      .box-thanks{
        padding: 42px 22px 45px 22px;
        color: #585859;
        text-align: center;

        .check-icon{
          color: #09ac04;
          font-size: 9rem;
        }

        .txt-purchased{
          font-size: 1.50rem;
        }

        .txt-course-name{
          margin-top: 4px;
          color: #1c1b1b;
          font-size: 1.85rem;
          font-weight: 700;
        }

        .txt-operation-id{
          margin-top: 20px;
          font-size: 1.2rem;

          u{
            color: #1c1b1b;
          }
        }

        p{
          font-size: 1.15rem;
          font-weight: 500;
        }
      }
    }
  }

}
