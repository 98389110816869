.btn-red-{
	color: #ffffff !important;
	border-color: $red;
  border-radius: 0.35rem;
	background-color: $red;

	&:hover, &:focus, &:active{
		border-color: $red2 !important;
		background-color: $red2 !important;
	}
}

.btn-outline-red-{
	color: $red !important;
	border-color: $red;
  border-radius: 0;
	background-color: transparent !important;

	&:hover, &:focus, &:active{
		color: $red2 !important;
		border-color: $red2 !important;
	}
}
