.btn-course-action{
  width: 220px;
  font-weight: 500;
  max-width: 100%;
}

#course-page{
  min-height: 60vh;
  padding-top: 20px;
  padding-bottom: 25px;

  .top-content{
    .g-title{
      color: $red;
      font-size: 1.45rem;
      font-weight: 500;
      text-transform: uppercase;

      a{
        color: $red;
        text-decoration: none !important;
      }
    }
  }

  .content-section{
    padding-top: 15px;

    >._main-row{
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }

    // == Col modulos ==
    .col-modules{
      &.hide-b-991{
        @media screen and (max-width: 991px){
          display: none !important;
        }
      }

      .box-modules{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;

        .items{
          position: relative;
          display: inline-block;
          width: 100%;
          margin-top: 6px;
          margin-bottom: 6px;

          ._link{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            -ms-flex-align: center !important;
            align-items: center !important;
            padding: 6px 33px 6px 13px;
            width: 100%;
            min-height: 36px;
            color: #867f7f;
            font-weight: 500;
            font-size: 1.0rem;
            line-height: 1.1;
            border: 1px solid rgba(0,0,0,0.5);
            border-radius: 0.35rem;
            @extend .t-150;

            .title{
              color: #353333;
              font-weight: 600;
              @extend .t-150;
            }

            .time{
              display: flex;
              flex: 0 0 100%;
              max-width: 100%;
            }

            &:hover{
              color: $red;
              border-color: $red;
              text-decoration: none;

              .title{
                color: $red;
              }
            }

            &.active, &.router-link-active{
              color: #ffffff !important;
              border-color: $red;
              background-color: $red;

              .title{
                color: #ffffff;
              }
            }

            &.completed{
              &:after{
                content: "\f058";
                position: absolute;
                top: 5px;
                right: 13px;
                color: $red;
                font-family: "Font Awesome 5 Pro";
                font-size: 1.0rem;
                font-weight: 700;
              }

              &.active{
                &:after{
                  color: #ffffff !important;
                }
              }
            }
          }

          &.checked{
            ._link{
              &:after{
                position: absolute;
                content: "\f00c";
                right: 9px;
                margin-top: 0;
                font-family: "Font Awesome 5 Free";
                font-size: 24px;
                font-weight: 700;
              }
            }
          }

        }
      }

      @media screen and (max-width: 1299px){
        .box-modules{
          .items{
            margin-top: 4px;
            margin-bottom: 4px;

            ._link{
              padding-right: 38px;
              min-height: 28px;
              font-size: 1.0rem;

              strong{
                font-size: 0.92rem;
              }

              &:after{
                font-size: 19px !important;
              }
            }
          }
        }
      }
    }
    // == ==

    // == ==
    .col-main-section{
      ._page{
        @extend .flex-center-xy;
        width: 100%;
        height: 100%;
        color: #3a3d3e;
        text-align: center;

        .icon{
          margin-bottom: 3px;
          font-size: 55px;
        }
        .title{
          margin-bottom: 5px;
          font-size: 1.10rem;
          font-weight: 600;
        }
        .modules{
          color: $red;
          font-weight: 600;
        }

        @media screen and (max-width: 991px){
          display: none;
        }
      }

      .main-content{
        position: relative;
        width: 100%;

        .box-top{
          position: relative;
          display: inline-block;
          margin-bottom: 16px;
          width: 100%;
          border-radius: 0.35rem;
          overflow: hidden;

          .box-title{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            color: #ffffff;
            background-color: $red;

            .col-title{
              padding: 14px 23px;

              >.title{
                font-size: 1.1rem;
                font-weight: 300;

                strong{
                  font-weight: 400;
                }
              }
            }

            .col-counter{
              padding: 10px 31px 10px 23px;
              max-width: 214px;

              .box-counter{
                display: flex;
                flex-wrap: wrap;
                flex: 0 0 100%;
                max-width: 100%;
                align-items: center;

                .col{
                  padding: 0;
                  text-align: center;
                  line-height: 1.0;
                }

                .col-time{
                  flex: 0 0 35px;
                  max-width: 35px;

                  .time{
                    display: block;
                    font-size: 1.4rem;
                    font-weight: 600;
                  }

                  small{
                    font-size: 0.70rem;
                    font-weight: 500;
                  }
                }

                .col-sep{
                  font-weight: 700;
                }
              }
            }
          }

          .box-pages{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 18px;
            border: 1px solid #979797;

            .col-page{
              padding-left: 0;
              padding-right: 0;
              border-right: 1px solid #979797;
              height: 45px;
              @extend .flex-center-xy;

              a{
                @extend .flex-center-xy;
                padding-left: 8px;
                padding-right: 8px;
                width: 100%;
                height: 100%;
                color: $red;
                font-size: 1.0rem;
                text-align: center;
                outline: 1px solid transparent;
                @extend .t-150;

                &:hover{
                  color: #ffffff !important;
                  text-decoration: none;
                  background-color: $red2;
                  outline-color: $red;
                  font-weight: 600;
                }

                &.active, &.router-link-exact-active{
                  color: #ffffff !important;
                  font-weight: 600;
                  outline-color: $red;
                  background-color: $red;
                }
              }

              &:last-child{
                border-right: 0;
              }
            }
          }
        }

        @media screen and (max-width: 1199px){
          .box-top{
            .box-title{
              .col-title{
                padding: 14px 23px;

                .title{
                  font-size: 1.0rem;
                }
              }
            }
          }
          .box-pages{
            .col-page{
              height: inherit !important;
              min-height: 36px;
              border-right: 0 !important;

              a{
                font-size: 0.85rem !important;
              }
            }
          }

        }
        @media screen and (max-width: 575px){
          .box-top{
            .box-title{
              justify-content: center;

              .col-title{
                flex: 0 0 100%;
                text-align: center;
                padding: 12px 23px 0 23px;

                .title{
                  font-size: 1.1rem;
                }
              }

              .col-counter{
                padding: 9px 31px 12px 23px;

                .box-counter{
                  .col-time{
                    .time{
                      font-size: 1.2rem;
                    }
                    small{
                      font-size: 0.65rem;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
    // == ==

  }
}

// == Caja de contenido en sección de Modulos ==
._box-module-content{
  position: relative;
  display: inline-block;
  padding: 19px 22px;
  width: 100%;
  //color: #737475;
  //font-size: 0.95rem;
  //line-height: 1.3;
  border-radius: 0.35rem;
  background-color: #edeef0;
  //word-break: break-word;

  a{
    color: $red;
  }

  ul{
    padding-left: 23px;
  }

  .title{
    margin-bottom: 4px;
    font-size: 1.15rem;
    font-weight: 600;
  }

  p{
    margin-bottom: 9px;
  }

  .box-body{
    .box-steps{
      position: relative;
      display: block;
      padding: 5px 0 15px 0;
      text-align: center;

      a{
        display: inline-block;
        color: #1f1f20;
        font-size: 1.3rem;
        font-weight: 700;
      }

      .sep{
        display: inline-block;
        padding: 0 8px;
        color: #606062;
        font-size: 1.3rem;
        font-weight: 700;
      }

      .next{
        position: relative;
        top: 3px;
        margin-left: 18px;
        font-size: 1.8rem;
        cursor: pointer;
        @include transition(150ms);

        &:hover{
          opacity: 0.85;
        }
      }
    }
  }
}
// == end ==

// == Curso de consulta ==
#u-course-page{
  position: relative;
  width: 100%;

  >.box-image{
    display: block;
    height: 280px;
    background-color: $soft-gray;
  }

  iframe{
    width: 100% !important;
  }

  .box-tab-videos{
    position: relative;
    width: 100%;

    .tab{
      display: inline-block;
      padding: 10px 18px 8px 18px;
      margin: 0 3px;
      min-width: 110px;
      color: #ffffff;
      cursor: pointer;
      line-height: 1.1;
      text-align: center;
      text-decoration: none !important;
      background-color: $red;

      &:hover{
        background-color: $red2;
      }

      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }

      &.active{
        font-weight: 700;
      }
    }
  }

  .box-videos{
    position: relative;
    width: 100%;
    border-radius: 0.35rem;
    overflow: hidden;

    .video{
      position: relative;
      display: block;
      background-color: #000000;

      iframe{
        width: 100%;
        height: 358px;
        background-color: #000000;
      }
    }
  }
}
// == ==

// == Ejercicios ==
#exercises-page{
  position: relative;
  width: 100%;
  color: #737475;
  font-size: 0.95rem;
  line-height: 1.3;
}
// == ==

// == Examen ==
#u-exam-page{
  position: relative;
  width: 100%;

  .box-top{
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 0.35rem;
    overflow: hidden;

    .box-title{
      position: relative;
      width: 100%;
      padding: 14px 23px;
      color: #ffffff;
      background-color: $red;

      >.title{
        font-size: 1.1rem;
        font-weight: 300;

        strong{
          font-weight: 400;
        }
      }
    }
  }

  .box-exam{
    position: relative;
    display: inline-block;
    margin-top: 10px;
    width: 100%;

    .question-answer{
      display: inline-block;
      width: 100%;

      .question{
        font-size: 1.05rem;
        margin-bottom: 6px;
      }

      .answers{
        padding-left: 5px;
        font-size: 0.96rem;

        .custom-control{
          margin-bottom: 5px;

          .custom-control-label{
            &:before{
              // background-color: $red;
            }
          }

          .custom-control-input:checked ~ .custom-control-label{
            color: $red;
            font-weight: 600;

            &:before{
              background-color: $red;
              border-color: $red;
            }
          }
        }
      }

      @media screen and (max-width: 767px){
        .question{
          font-size: 0.96rem;
          line-height: 1.3;
        }
        .answers{
          font-size: 0.93rem;
        }
      }
    }
  }
}

#box-course-btns{
  position: relative;
  margin-top: 15px;
  width: 100%;

  a, button{
    margin: 0.25rem;

    &:first-child{
      margin-left: 0 !important;
    }
  }

  @media screen and (max-width: 767px){
    a, button{
      width: auto !important;
      padding: .3rem 1.45rem 0.35rem 1.45rem !important;
      font-size: 0.95rem !important;
    }
  }
}
