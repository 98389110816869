.cus-f-group-1{
  position: relative;
  margin-top: 5px;
  margin-bottom: 1.6rem;

  &.no-label{ // Agregar clase css cuando no se tendrá un label en el componente
    >div{
      padding-top: 0 !important;
    }
  }

  >label, >legend{
    position: absolute;
    display: inline-block;
    padding: 0 6px;
    width: auto;
    max-width: 85%;
    top: -6px;
    left: 28px;
    color: #9b9b9b;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1;
    background: #fff;
    z-index: 5;
    pointer-events: none !important;
  }

  >div{
    position: relative;
    padding-top: 4px;
    padding-bottom: 2px;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 0.40rem;

    .custom-select, .form-control{
      color: #353535 !important;
      border: 0 !important;
      border-radius: 0;
      background-color: transparent !important;
    }

    .custom-select{
      option{
        &:first-child{
          color: #999999;
        }
      }
    }

    textarea{
      resize: none;
    }

    .custom-radio{
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 42px;
      line-height: 1.1;

      &:first-child{
        margin-top: 9px;
      }
      &:last-child{
        margin-bottom: 5px;
      }

      .custom-control-label{
        width: auto;
        color: #353535 !important;
        font-weight: 400;
        line-height: 1;

        &:before, &:after{
          top: -0.05rem;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #000000;
        border-color: #000000;
      }
    }

    .fileinput{
      padding: 9px 6px 7px 6px;

      .fileinput-con{
        max-width: 100%;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 575px){
    padding-top: 4px;

    >div{
      padding-top: 7px;

      .fileinput{
        padding-top: 5px;
      }
    }
  }

  &.c-f-g-pass{
    >div{
      position: relative;
      padding-top: 4px;
      padding-bottom: 2px;
      width: 100%;
      border: 1px solid #979797;
      border-radius: 0.40rem;

      .custom-select, .form-control{
        padding-right: 51px;
      }

      .ic-eye{
        position: absolute;
        padding: 8px 14px;
        top: calc(50% - 17px);
        right: 3px;
        font-size: 1.2rem;
        cursor: pointer;
        z-index: 30;
      }

      .fa-eye-slash{
        right: 2px !important;
      }
    }
  }

  &.exp-date{
    >div{
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: wrap;

      select{
        padding-left: 6px;
        padding-right: 6px;
        flex: 0 0 calc(50% - 3px);
        max-width: calc(50% - 3px);
        background-size: 7px;
        background-position: right 0.55rem center;
        background-image: none !important;

        &:first-child{
          text-align: right;
          flex: 0 0 38%;
          max-width: 38%;
        }
        &:last-child{
          flex: 0 0 calc(62% - 6px);
          max-width: calc(62% - 6px);
        }
      }

      .line{
        display: flex;
        padding: 0.45rem 0 0.375rem 0;
        flex: 0 0 6px;
        max-width: 6px;
      }
    }
  }
}

.cus-f-checkbox{
  padding-left: 25px;

  .custom-control-label{
    color: #6a6969;
    font-size: 0.95rem;

    a{
      color: #6a6969;
      text-decoration: underline;
    }
  }

  .custom-control-input:checked ~ .custom-control-label{
    &:before{
      background-color: #000000;
      border-color: #000000;
    }
  }
}

.cus-f-checkbox-2{
  display: inline-block;
  padding-left: 0;
  cursor: pointer;

  input[disabled] ~ .custom-control-label {
    cursor: default;

    span{
      cursor: default;
    }

    &:before{
      opacity: 0.7;
    }
  }

  .custom-control-label{
    padding-left: 2.8rem;
    padding-right: 1.2rem;
    cursor: pointer;

    &:before{
      content: "\f204";
      display: inline-block;
      width: inherit;
      height: inherit;
      // top: -2px;
      top: calc(50% - 10px);
      left: 0;
      color: #241e1b;
      font-family: "Font Awesome 5 Pro";
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1;
      border: 0;
      cursor: pointer;
      pointer-events: none;
      background-color: transparent !important;
    }

    &:after{
      display: none;
    }

    span{
      position: relative;
      display: block;
      top: 3px;
      cursor: pointer;
      line-height: 1.1;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before{
    content: "\f205";
    color: #241e1b;
    border-color: inherit;
    background-color: transparent !important;
  }

  &.control-sm{
    .custom-control-label{
      font-size: 0.90rem;
      padding-left: 2.2rem;

      &:before{
        font-size: 1.5rem;
      }
    }
  }
}

.cus-f-group-2{
  >div{
    position: relative;

    .form-control{}
  }

  &.c-f-g-pass{
    >div{
      position: relative;

      .form-control{
        padding-right: 48px;
      }
    }

    .ic-eye{
      position: absolute;
      padding: 8px 14px;
      top: calc(50% - 17px);
      right: 3px;
      font-size: 1.2rem;
      cursor: pointer;
      z-index: 30;
    }

    .fa-eye-slash{
      right: 2px !important;
    }
  }
}
