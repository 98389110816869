body,html{
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: #241e1b;
	background-color: #eff2f2;
}

#app {
	max-width: 2000px;
  margin: 0 auto;
	box-shadow: 0 1px 2px rgba(0,0,0,0.15);
	background-color: #ffffff;
	overflow-x: hidden;
}

p,h1,h2,h3,h4,h5,h6{
	margin-bottom: 0px;
}

// Container
.oversized-container{
	@media screen and (min-width: 992px){
		max-width: 98%;
	}
	@media screen and (min-width: 1630px){
		max-width: 1590px;
	}
}

// Backgrounds
.placed-backg{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Remove outline
select:focus, select:active, select:hover, button:focus, button:active, button:hover,
input:focus, input:active, input:hover, textarea:focus, textarea:active, textarea:hover{
  box-shadow: none !important;
  outline: none !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}

// Remove Arrows/Spinners
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.sweet-modal{
	.sweet-content{
		padding-top: 36px !important;
		padding-bottom: 36px !important;
	}
}

.box-success-check{
	position: relative;
	display: inline-block;
	width: 100%;
	height: 110px;

	.checkmark__circle {
	  stroke-dasharray: 216; /* ORIGINALLY 166px */
	  stroke-dashoffset: 216; /* ORIGINALLY 166px */
	  stroke-width: 2;
	  stroke-miterlimit: 10;
	  stroke: #7ac142;
	  fill: none;
	  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}

	.checkmark {
	  width: 106px; /* ORIGINALLY 56px */
	  height: 106px; /* ORIGINALLY 56px */
	  border-radius: 50%;
	  display: block;
	  stroke-width: 2;
	  stroke: #fff;
	  stroke-miterlimit: 10;
	  margin: 0 auto;
	  box-shadow: inset 0px 0px 0px #7ac142;
	  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
	}

	.checkmark__check {
	  transform-origin: 50% 50%;
	  stroke-dasharray: 98; /* ORIGINALLY 48px */
	  stroke-dashoffset: 98; /* ORIGINALLY 48px*/
	  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	}

	@keyframes stroke {
	  100% {
	    stroke-dashoffset: 0;
	  }
	}
	@keyframes scale {
	  0%, 100% {
	    transform: none;
	  }
	  50% {
	    transform: scale3d(1.1, 1.1, 1);
	  }
	}
	@keyframes fill {
	  100% {
	    box-shadow: inset 0px 0px 0px 80px #7ac142;
	  }
	}
}

.box-successful-exam{
	position: relative;
	padding: 20px 0;

	.btn-close{
		position: absolute;
		top: 0;
		right: 0;
		color: #7b0606;
		font-size: 1.7rem;
		cursor: pointer;
		z-index: 15;
	}

	.box-content{
		position: relative;
		display: block;
		color: #241e1b;
		text-align: center;

		.congratulations{
			font-size: 1.85rem;
			font-weight: 700;
		}

		._btn{
			display: inline-block;
			padding: 9px 15px 9px 15px;
			margin: 3px 9px;
			width: 262px;
			color: #241e1b;
			cursor: pointer;
			font-size: 0.85rem;
			font-weight: 600;
			text-align: center;
			text-decoration: none !important;
			text-transform: uppercase;
			border: 1px solid #241e1b;
			line-height: 1.2;

			&:first-child{
				margin-left: 0;
			}
			&:last-child{
				margin-right: 0;
			}

			&:hover{
				color: #ffffff;
				background-color: #241e1b;
			}
		}
	}
}

.sweet-action-close{
	color: $red;

	&:hover{
		color: #ffffff !important;
		background-color: $red !important;
	}
}

.b-radius-6 {
	border-radius: 6px !important;
}
.b-radius-8 {
	border-radius: 8px !important;
}
