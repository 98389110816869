#header{
	position: relative;
	width: 100%;
	height: 165px;
	z-index: 99;

	.wr-h {
		max-width: 2000px;
	  margin: 0 auto;
		box-shadow: 0 1px 2px rgba(0,0,0,0.15);
	}

	@media screen and (max-width: 991px){
		height: 66px;
	}

	.header-content{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		.navbar{
			&.bg-light{
				background-color: #ffffff !important;
			}

			.navbar-brand{
				padding-top: 0.1rem;
				padding-bottom: 0.1rem;

				img{
					height: 80px;
				}
			}

			.btn-extras {
				margin-left: auto;
				padding-right: 25px;

				a {
					display: inline-block;
					padding: 7px 12px;
					color: #ffffff;
					font-weight: 500;
					text-decoration: none !important;
					border-radius: 3px;
					background-color: $red;
				}

				@media screen and (min-width: 992px) {
					display: none;
				}
			}

			.navbar-toggler{
				padding: 0.40rem 0.85rem;
				border-radius: 3px;
				border-color: #484848;

				.icon{
					color: #484848;
					font-size: 1.6rem;
				}
			}

			#nav-collapse{
				.navbar-nav{
					.simple-item{
						.nav-link{
							color: $black;
							cursor: pointer;
							font-size: 0.95rem;
							border-radius: 6px;
							opacity: 1;

							&:hover{
								color: $red;
							}
						}

						&.question-item{
							.nav-link{
								font-size: 0.90rem;
								font-weight: 600;
								line-height: 1.2;
								cursor: default;

								a{
									color: #241e1b;
									text-decoration: underline;
								}

								&:hover{
									color: $black;
								}
							}
						}

						&.focus {
							.nav-link {
								color: #ffffff !important;
								font-weight: 600;
								background-color: $red;
							}
						}
					}

					.courses-dropdown {
						.dropdown-menu {
							margin-top: 0;
							text-transform: uppercase;
						}
						&:hover {
							.dropdown-menu {
								display: block !important;
							}
						}
						a{
							color: #241e1b;
							text-decoration: none;
						}
					}

					.icon-item{
						margin-right: 6px;
						margin-left: 6px;

						&:first-child{
							margin-left: 0;
						}
						&:last-child{
							margin-right: 0;
						}

						>.nav-link{
							@include flex-center-xy();
							flex-wrap: wrap;
							width: 42px;
							height: 42px;
							flex: 0 0 42px;
							max-width: 42px;
							color: $black;
							cursor: pointer;
							border: 1px solid $black;
							border-radius: 50%;

							.icon{
								font-size: 1.2rem;

								&.fa-user{
									font-size: 1.4rem;
								}
							}

							&:after{
								display: none;
							}

							&:hover{
								color: $red;
								border-color: $red;
							}
						}

						&.red-icon {
							>.nav-link {
								color: #ffffff !important;
								border-color: $red;
								background-color: $red;
							}
						}
					}

					.dropdown{
						.dropdown-menu{
							padding-top: 0.2rem;
							padding-bottom: 0.2rem;
							color: #ffffff !important;
							border: 0;
							box-shadow: 0 0 1px rgba(255,255,255,0.7);
							border-radius: 3px;
							background-color: $red2;

							li{
								.dropdown-item{
									padding: 0.20rem 1.2rem;
									color: #ffffff !important;
									font-size: 0.95rem;
									cursor: pointer;

									&:hover, &:active, &:focus{
										background-color: #970412 !important;
									}
								}
							}
						}

						&.show{
							>.nav-link{
								border-color: $red;
								color: $red;
							}
						}
					}
				}
			}
		}

		.extra-navbar-menu {
			color: #ffffff;
			background-color: $red !important;

			#nav-collapse {
				.navbar-nav {
					.dropdown {
						>.nav-link {
							color: #ffffff !important;
							font-weight: 600;
							text-transform: uppercase;

							a {
								color: #ffffff;
							}

							@media screen and (min-width: 992px) {
								padding-top: 0.2rem;
								padding-bottom: 0.2rem;
							}
						}

						.dropdown-menu {
							margin-top: 0;
						}

						&:hover {
							.dropdown-menu {
								display: block !important;
							}
						}

						&.no-subcategories {
							.dropdown-toggle {
								&:after {
									display: none !important;
								}
							}
							.dropdown-menu {
								display: none !important;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px){
			.navbar{
				#nav-collapse{
					.navbar-nav{
						.simple-item{
							margin-left: 0.6vw;
							margin-right: 0.5vw;

							.nav-link{
								/*padding-left: 1.1rem;
								padding-right: 1.1rem;*/
								padding-left: .5rem;
								padding-right: .5rem;
								border: 1px solid transparent;
							}

							.router-link-exact-active {
								color: $red !important;
								border-color: $red;
								border-radius: 6px;
							}
						}
					}
				}
			}

			@media screen and (max-width: 1299px){
				.navbar{
					#nav-collapse{
						.navbar-nav{
							.simple-item{
								margin-left: 0.1vw;
								margin-right: 0.1vw;

								.nav-link{
									padding-left: 0.8rem;
									padding-right: 0.8rem;
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: 1199px){
				.navbar{
					#nav-collapse{
						.navbar-nav{
							.simple-item{
								&.question-item{
									display: none !important;
								}
							}

							.dropdown {
								>.nav-link {
									font-size: 0.85rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px){
			.navbar{
				.navbar-brand{
					padding-top: 0;
					padding-bottom: 0;

					img{
						height: 50px;
					}
				}

				#nav-collapse{
					.navbar-nav{
						.simple-item{
							.nav-link{
								padding-top: 0.2rem;
								padding-bottom: 0.2rem;
							}

							.router-link-exact-active {
								color: $red;
								font-weight: 700;
							}

							&.focus {
								.nav-link {
									padding-left: 12px,
								}
							}
						}

						.question-item{
							margin-top: 5px;
						}
					}
				}
			}

			.extra-navbar-menu {
				display: none !important;
			}
		}
	}

}
